<template>
  <div id="app">
<!--    <button color="success" @click="Regenerate">Regenerate</button>-->
    <transition name="fade">
      <Splash v-if="refresh" />
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {
    Splash: () => import("./components/Spash.vue"),
  },
  data: () => ({
    refresh: true,
  }),
  methods: {
    Regenerate() {
      // this.refresh = !this.refresh;

      this.refresh = false;
      setTimeout(() => {
        this.refresh = true;
      }, 300);
    },
  },
};
</script>

<style>
html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.button {
  margin-top: 3%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
